import { useState, useEffect } from "react";

import whitePawn from "../imgs/p-w.png";
import blackPawn from "../imgs/p-b.png";

export default function Piece ({piece, position, yourColor, turn, selectPiece}){

    const {type, color} = piece
    const selectThisPiece = event => {
        if (color === yourColor && turn === yourColor) {
            selectPiece(position)
        }
    }
    const [pieceImg, setPieceImg] = useState()

    
    useEffect(() => {
        if(!type || !color) return;
        setPieceImg(require(`../imgs/${type}-${color}.png`))
        
    }, [type, color]);
    return(
        <div className="piece-container">
            {color === yourColor && <div className="piece-click" onClick={selectThisPiece}></div>}
            <img src={pieceImg} alt="" className="piece" />
        </div>
    )
}

export const PawnPresentational = ({color}) => {
    return (
        <div className="piece-container">
            <img src={color === "w"? whitePawn : blackPawn} alt="" className="piece" />
        </div>
    )
}