import { useEffect, useState, useContext } from "react";
import "./chess.css";
import "./mobile.css"
import "./wide.css"
import { ethers } from "ethers";
import { Link, useParams } from "react-router-dom";

import Board from "./components/Board";
import { gameSub, room } from "../../types/types";
import { gameSubject, getSubject } from "./utils/Game";
import { RoomContext } from "../../context/RoomContext";
import { UserContext } from "../../context/UserContext";
import { LoadingContext } from "../../context/LoadingContext";
import { ContractContext } from "../../context/ContractContext";
import { SettingsContext } from "../../context/SettingsContext";
import {
    deleteRoom,
    callTimeOver,
    pingMove
} from "../../utils/firebase-utils/firebase.utils";
import { CONTRACT_ADDRESS } from "../../constants";
import ChessEth from "../../utils/ethers-utils/ChessEth.json";
import { formatSeconds } from "../../utils/formatSeconds";
import Settings from "../../components/Settings";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { PromotionContext } from "../../context/PromotionContext";
import Promotion from "./components/Promotion";
import { fetchEth } from "../home/utils/fetchPrice";

export default function Chess() {
    const {
        reset,
        roomNumber,
        setRoomNumber,
        group,
        yourColor,
        setGameOver,
        wallet,
        opponentWallet,
        setGroup,
        setWallet,
        setYourColor,
        setOpponentWallet,
    } = useContext(RoomContext);

    const {wagerIsInEth} = useContext(SettingsContext)

    const params = useParams();

    const { walletProvider} = useWeb3ModalProvider()


    const { contract, setContract } = useContext(ContractContext);
    const { user, setUser } = useContext(UserContext);
    const { createLoadingMessage } = useContext(LoadingContext);
    const { showSettings } = useContext(SettingsContext)
    const { promotion } = useContext(PromotionContext)
    const [board, setBoard] = useState([]);
    const [turn, setTurn] = useState("");
    const [result, setResult] = useState("");
    const [lastMove, setLastMove] = useState({from: '', to: ''})
    const [isGameOver, setIsGameOver] = useState(false);
    const [ethPrice, setEthPrice] = useState(0)

    const [yourBalance, setYourBalance] = useState<number>(0);
    const [sumBalances, setSumBalances] = useState<number>(0);
    const turnTime = 120;
    const gameTime = 600;
    const [yourTurnTimer, setYourTurnTimer] = useState(turnTime);
    const [oppTurnTimer, setOppTurnTimer] = useState(turnTime);
    const [yourTimeLeft, setYourTimeLeft] = useState(gameTime);
    const [oppTimeLeft, setOppTimeLeft] = useState(gameTime);

    useEffect(() => {
        if (!walletProvider) return;
        const getContract = async () => {
            if(contract) return
            const provider = new ethers.BrowserProvider(walletProvider)
            const signer = await provider.getSigner()
            const contract_ = new ethers.Contract(
                CONTRACT_ADDRESS,
                ChessEth.abi,
                signer
            );
            setContract(contract_);
        }

        getContract()
    }, [walletProvider]);

    useEffect(() => {
        pingMove()
        const fetchEthPrice = async () => {
            const data = await fetchEth()
            setEthPrice(data)
        }
        if(wagerIsInEth ) {
            fetchEthPrice()

        }
    }, []);
    


    useEffect(() => {
        if ((!group || !roomNumber) && params.group && params.room) {
            setGroup(params.group);
            setRoomNumber(params.room);
        }
        let subscribe: any;
        if (!gameSubject) {
            if (!group || !user || !roomNumber) return;
            getSubject(group, roomNumber, user);
        }

        subscribe = gameSubject.subscribe(async (room: room) => {
            if(!room || !wallet) return;
            const game = room.game as gameSub;
            const playerWallets = room.players.map((v) => v.wallet);
            
            if (!user || !wallet || !playerWallets.includes(wallet) || !group || !roomNumber) return;
            
            setBoard(game.board);
            setTurn(game.turn);
            setIsGameOver(game.gameOver);
            setLastMove({from: game.lastMoveFrom, to: game.lastMoveTo})
            
            const turnTimeLeft = parseInt((((game.startTime - Date.now() + turnTime * 1000) / 1000) - 1).toFixed())
            
            if(isNaN(turnTimeLeft)){
                setYourTurnTimer(turnTime)
                setOppTurnTimer(turnTime)
            } else if(turnTimeLeft < 0) {
                setYourTurnTimer(0)
                setOppTurnTimer(0)
            } else {
                setYourTurnTimer(turnTimeLeft)
                setOppTurnTimer(turnTimeLeft)
            }
            const thisPlayer = room.players.find(p => p.id === user.uid)
            const oppPlayer = room.players.find(p => p.id !== user.uid)
            if(thisPlayer){
                const gameTimeLeft = parseInt(((thisPlayer.gameTimeLeft) / 1000).toFixed())
                setYourTimeLeft(gameTimeLeft)
            }
            if(oppPlayer){
                const gameTimeLeft = parseInt(((oppPlayer.gameTimeLeft) / 1000 ).toFixed())
                setOppTimeLeft(gameTimeLeft)
            }
            

            setYourColor(
                room.players[0].id === user.uid
                ? room.players[0].color
                : room.players[1].color
                );
                
            const opp = room.players[0].id === user.uid? room.players[1] : room.players[0];
            const oppWallet_ = opp.wallet;

            setOpponentWallet(oppWallet_);
            if (game.gameOver && !result) {
                const result = game.winner === user.uid? 'win' : game.winner === 'draw'? 'draw' : game.winner === opp.id? 'loss' : null
                if(!result) return;
                setResult(result)
                const getBalance = async () => {
                    if (!contract) return;
                    const yourTokens = await contract.lockedTokensOfAddress(
                        wallet
                    );
                    const oppTokens = await contract.lockedTokensOfAddress(
                        oppWallet_
                    );
                    setYourBalance(
                        parseFloat(ethers.formatEther(yourTokens))
                    );
                    parseFloat(ethers.formatEther(oppTokens));
                    setSumBalances(
                        parseFloat(ethers.formatEther(yourTokens)) +
                            parseFloat(ethers.formatEther(oppTokens))
                    );
                };
                getBalance();
                if (result === "loss") {
                    reset();
                }
                return () => subscribe && subscribe.unsubscribe();
            }
        });
        return () => subscribe && subscribe.unsubscribe();
    }, [gameSubject, group, user]);

    useEffect(() => {
        if(!board[0]) return;
        const timer = setInterval(() => {
            if(!board[0]) return;
            if (turn === yourColor) {
                setYourTurnTimer((time) =>{
                    if(time <= 0) return 0
                    return time - 1
                });
                setYourTimeLeft((time) =>{
                    if(time <= 0) return 0
                    return time - 1
                });
            } else {
                setOppTurnTimer((time) =>{
                    if(time <= 0) return 0
                    return time - 1
                });
                setOppTimeLeft((time) =>{
                    if(time <= 0) return 0
                    return time - 1
                });
            }
        }, 1000);
        return () => clearInterval(timer);
    }, [turn]);

    useEffect(() => {
        if (
            turn == yourColor ||
            oppTurnTimer > 0 ||
            !group ||
            !roomNumber || !board[0]
            ) return;
            const cd = setInterval(() => {
                callTimeOver(group, roomNumber);
            },10000)
            if(isGameOver) {
                clearInterval(cd)
            }
        
        return () => clearInterval(cd)
    }, [oppTurnTimer]);
    useEffect(() => {
        if (
            turn == yourColor ||
            oppTimeLeft > 0 ||
            !group ||
            !roomNumber || !board[0]
            ) return;
            const cd = setInterval(() => {
                callTimeOver(group, roomNumber);
            },10000)
            if(isGameOver) {
                clearInterval(cd)
            }
        
        return () => clearInterval(cd)
    }, [oppTimeLeft]);

    const GameOver = (
        <div className="game-over-field">
            {result === "win" ? (
                <>
                    <p className="fs-3">You won<br/>{sumBalances} ETH!</p>
                    <Link to="/" className="fs-5">
                        Return to home 
                    </Link>
                </>
            ) : result === "draw" ? (
                <>
                    <p className="fs-4">'It's a tie</p>
                    <Link to="/" className="fs-5">
                        Return to home 
                    </Link>
                </>
            ) : result === "loss"? (
                <>
                    <p className="fs-4">You lost</p>
                    <Link to="/" className="fs-5">
                        Return to home 
                    </Link>
                </>
            ):
            <>
                <p className="fs-3">Verifying game result...</p>
            </>
            }
        </div>
    );


    const oppTurnField = (
        <div className="turn-field">
            <p className="fs-3 bold">{formatSeconds(oppTimeLeft)}</p>
            {yourColor !== turn && <p className="fs-5 center-self">Opponents turn</p>}
            <p className="fs-3 bold">{formatSeconds(oppTurnTimer)}</p>
        </div>
    )
    const yourTurnField = (
        <div className="turn-field">
            <p className={`fs-3 bold ${yourTimeLeft < 20 && yourTimeLeft % 2 === 1 && "text-red"}`}>
                        {formatSeconds(yourTimeLeft)}
                    </p>
            {yourColor === turn && <p className="fs-5 center-self">Your turn</p>}
            <p className={`fs-3 bold ${yourTurnTimer < 20 && yourTurnTimer % 2 === 1 && "text-red"}`}>
                        {formatSeconds(yourTurnTimer)}
                    </p>
        </div>
    )


    return (
        showSettings? <Settings /> :
      <div className="chess-container">
            {isGameOver && GameOver}
            <div className="game-container">
                {!isGameOver && oppTurnField}
                <div className="board-border">
                    <div className="board-container">
                        <Board board={board} turn={turn} yourColor={yourColor} lastMove={lastMove} />
                    </div>
                </div>
                {!isGameOver && yourTurnField}
            </div>
            {promotion && promotion.color == yourColor && <Promotion promotion={promotion as any} />}
        </div>
    );
}
