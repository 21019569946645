import knight_w from '../../chess/imgs/n-w.png'
import knight_b from '../../chess/imgs/n-b.png'
import pawn_w from '../../chess/imgs/p-w.png'
import pawn_b from '../../chess/imgs/p-b.png'
import queen_w from '../../chess/imgs/q-w.png'
import queen_b from '../../chess/imgs/q-b.png'
import rook_w from '../../chess/imgs/r-b.png'
import bishop_w from '../../chess/imgs/b-w.png'
import { useContext, useEffect, useState } from 'react'
import { SettingsContext } from '../../../context/SettingsContext'
import { fetchEth } from '../utils/fetchPrice'


type props = {
    wager: string;
    group: string;
    findMatch: (arg0: string, arg1: string) => {};
    piece: string;
    referred: boolean;
};

export function JoinGameButton({ wager, group, findMatch, piece, referred }: props) {
    let piece_w = piece === 'p'? pawn_w : piece === 'n' ? knight_w : piece === 'q' ? queen_w : null
    let piece_b = piece === 'p'? pawn_b : piece === 'n' ? knight_b : piece === 'q' ? queen_b : null

    const {wagerIsInEth} = useContext(SettingsContext)

    const [ethPrice, setEthPrice] = useState(0)

    useEffect(() => {
        const fetchEthPrice = async () => {
            const data = await fetchEth()
            setEthPrice(data)
        }
        fetchEthPrice()
    },[])


    const win = wagerIsInEth && ethPrice != 0 ? `Win ${(parseFloat(wager) * (referred? 1.82 :1.8)).toFixed(referred? 4 : 3)} ETH` : `Win ${(parseFloat(wager) * ethPrice * (referred? 1.82 :1.8)).toFixed()}$`

    return (
        <button
            className={`button join-game--button join-${piece}`}
            onClick={() => findMatch(wager, group)}
            >
            <p className="fs-5">
               {win}
            </p>
            <div className="fs-7">Bet {wagerIsInEth && ethPrice != 0? wager + " ETH" : (parseFloat(wager) * ethPrice).toFixed(0) + "$"}</div>
        </button>
    );
}

export function CustomGameButton({showCustomGame}: {showCustomGame: () => void}) {
    return (
        <button
            className="button join-game--button join-c"
            onClick={showCustomGame}
        >
            <p>Custom game</p>
        </button>
    );
}

