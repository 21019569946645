import { useContext } from "react";
import { RoomContext } from "../../../context/RoomContext";
import Square from "./Square"
import Piece, { PawnPresentational } from "./Piece"
import Promotion from "./Promotion"
import { useEffect, useState } from "react"
import { gameSubject, handleMove, tempChess, getLastMove } from "../utils/Game";
import { PromotionContext } from "../../../context/PromotionContext";

export default function BoardSquare({piece, black, position, lastMove, yourColor, selectPiece, legalMoves, selectedPiece, clearSelectedPiece, turn}) {
    const [highlighThisPosition, setHighlightThisPosition] = useState(false)
    const {promotion, setPromotion} = useContext(PromotionContext)
    const [gameOver, setGameOver] = useState(false)
    const {group, roomNumber} = useContext(RoomContext)

    useEffect(() => {
        setHighlightThisPosition(highlightPosition())
    },[legalMoves])

    useEffect(() => {
        if(!gameSubject)return;
        const subscribe = gameSubject.subscribe(room => {
            if(!room) return
            const {game: {pendingPromotion, gameOver}} = room
            if(gameOver) setGameOver(gameOver);
            
            if(pendingPromotion && pendingPromotion.to === position) setPromotion(pendingPromotion) 
            if(!pendingPromotion) setPromotion(null)
        })
        return () => subscribe.unsubscribe()
    },[position])

    const highlightPosition = () => {
        if(legalMoves){
            return legalMoves.map(v => v.to).includes(position)
        }
        else{
            return false
        }
    } 

    function moveToHere() {
        if(highlighThisPosition && selectedPiece !== position && !gameOver){
            handleMove(group, roomNumber, selectedPiece,position)
            clearSelectedPiece()
            if(!promotion) {
                try {
                    tempChess.move({from: selectedPiece, to: position})
                    const f = tempChess.fen()
                    tempChess.load(f)
                    
                } catch (error) {
                    
                }
            }
        }
    }
    return(
        <div className="board-square">
            <div className="move-click" onClick={moveToHere}></div>
            <Square position={position} lastMove={lastMove} black={black} highlightSelect={selectedPiece == position} highlightThisPosition={highlighThisPosition} highlightOpp={true} yourColor={yourColor}  turn={turn}>
               {piece && (promotion ? promotion.from != position && promotion.to != position : true) && <Piece piece={piece} position={position} yourColor={yourColor} clearSelectedPiece={clearSelectedPiece} selectedPiece={selectedPiece} turn={turn} selectPiece={selectPiece}  />}
               {promotion && promotion.to == position && <PawnPresentational color={promotion.color} />}
            </Square>
        </div>
    )

}