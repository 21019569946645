import { useContext, useEffect, useRef, useState } from "react";
import { SettingsContext } from "../context/SettingsContext";
import { UserContext } from "../context/UserContext";
import { Link, useLocation } from "react-router-dom";
import useOutsideClick from "../hooks/use-outside-click";


export default function Settings() {

    const {highlights, setHighlights, setShowSettings, buttonRef, wagerIsInEth, setWagerIsInEth} = useContext(SettingsContext)
    const { refCode } = useContext(UserContext);
    const ref = refCode.toLowerCase()
    const location = useLocation();
    const [highlightsSwitch, setHighlightSwitch] = useState(false)
    const [wagerModeSwitch, setWagerModeSwitch] = useState(false)

    const settingsRef = useRef(null)


    useOutsideClick(settingsRef, () => {
        if(!buttonRef.current) return
        setShowSettings(false)
    }, buttonRef)

    useEffect(() => {   
        const savedHighlights = localStorage.getItem("highlights")
        const savedWagerFormat = localStorage.getItem("wagerMode")
        
        if(savedHighlights != undefined && typeof JSON.parse(savedHighlights) == "boolean") {
            setHighlights(JSON.parse(savedHighlights))
        }
        if(savedWagerFormat != undefined && typeof JSON.parse(savedWagerFormat) == "boolean") {
            console.log(JSON.parse(savedWagerFormat));
            
            setWagerIsInEth(JSON.parse(savedWagerFormat))
        }
    },[])    


    return ( 
        <div ref={settingsRef} className="settings settings-mobile">
            <div className="flex-vert gap-1">
                <p className="fs-2">Settings</p>
                <br />
                <div className="row-apart">
                    Show possible moves
                    <button onClick={() => {setHighlightSwitch(true);setHighlights(prev => !prev); localStorage.setItem("highlights", JSON.stringify(!highlights))}} className={`pointer button settings-button ${!highlightsSwitch? highlights? 'setting-on': 'setting-off' : highlights? 'switch-on': 'switch-off'}`}></button>
                </div>
                <div className="row-apart">
                    Show wagers in USD
                    <button onClick={() => {setWagerModeSwitch(true);setWagerIsInEth(prev => !prev); localStorage.setItem("wagerMode", JSON.stringify(!wagerIsInEth))}} className={`pointer button settings-button ${!wagerModeSwitch? !wagerIsInEth? 'setting-on': 'setting-off' : !wagerIsInEth? 'switch-on': 'switch-off'}`}></button>
                </div>
            </div>
            {(location.pathname == '/' || location.pathname == `/${ref}` || location.pathname == '/referral-program') && 
                <Link onClick={() => setShowSettings(false)} className="mobile-only button header-link ref-link fs-6" to={location.pathname == '/' || location.pathname == `/${ref}` ? '/referral-program' : `/${ref ??  ''}`}>{location.pathname == '/'? 'Referral program' : 'Home'}</Link>  
            }
        </div>
     );
}