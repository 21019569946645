import React, {useState, createContext, SetStateAction, Dispatch, useRef} from 'react'
type context = {
    showSettings: boolean,
    setShowSettings: Dispatch<SetStateAction<boolean>>,
    highlights: boolean,
    setHighlights: Dispatch<SetStateAction<boolean>>,
    wagerIsInEth: boolean,
    setWagerIsInEth: Dispatch<SetStateAction<boolean>>,
    buttonRef: any
}

type props = {
    children: React.ReactNode
}

export const SettingsContext = createContext<context>({} as context) 

export const SettingsContextProvider = ({children}: props) => {
    const [highlights, setHighlights] = useState(true)
    const [wagerIsInEth, setWagerIsInEth] = useState(true)
    const [showSettings, setShowSettings] = useState(false);
    const buttonRef = useRef(null)
    return (
        <SettingsContext.Provider value={{showSettings, setShowSettings,highlights,setHighlights, buttonRef, wagerIsInEth, setWagerIsInEth}}>
            {children}
        </SettingsContext.Provider>
    )
}
