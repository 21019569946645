import { useContext } from "react";
import { RoomContext } from "../../../context/RoomContext";
import { handleMove, tempChess } from "../utils/Game";

import Square from "./Square"
import { PromotionContext } from "../../../context/PromotionContext";
const promotionPieces =  ['r', 'n', 'b', 'q']

export default function Promotion({promotion: {from, to, color}}) {

    const {group, roomNumber} = useContext(RoomContext)
    const {setPromotion} = useContext(PromotionContext)
    return (
        <div className="promotions">
            {promotionPieces.map((p,i) => (
                <div key={i} className="promote-square">
                    <Square black={i%2===0}>
                        <div className="piece-container" onClick={() => {
                            tempChess.move({from, to, promotion: p})
                            handleMove(group, roomNumber, from, to, p)
                            setPromotion(null)
                            }} >
                            <img src={require(`../imgs/${p}-${color}.png`)} alt="" className="piece" />
                        </div>
                    </Square>
                </div>
            ))}
        </div>
    )
}