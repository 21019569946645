import { ethers, lock } from "ethers";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContractContext } from "../../context/ContractContext";
import { RoomContext } from "../../context/RoomContext";
import { SettingsContext } from "../../context/SettingsContext";
import { UserContext } from "../../context/UserContext";
import { LoadingContext } from "../../context/LoadingContext";
import { room, wagers } from "../../types/types";
import { CONTRACT_ADDRESS } from "../../constants";
import ChessEth from "../../utils/ethers-utils/ChessEth.json";
import {
    joinRoom,
    listenForValue,
    validateLock,
    setStatus,
    signOutUser,
    deleteRoom,
    getRoom,
    getWagers,
    getAddressFromCode,
} from "../../utils/firebase-utils/firebase.utils";
import { initGame } from "../chess/utils/Game";
import { JoinGameButton, CustomGameButton } from "./components/JoinGameButton";
import { CustomGame } from "./components/CustomGame";
import { DataSnapshot} from "firebase/database";
import pawn from '../chess/imgs/p-b.png'
import knight from '../chess/imgs/n-w.png'
import rook from '../chess/imgs/r-b.png'
import queen from '../chess/imgs/q-w.png'
import chessBoardHome from '../../svgs/chess_floating_chessboard.gif'

import { useWeb3ModalAccount, useWeb3Modal, useWeb3ModalProvider } from '@web3modal/ethers/react'
import Settings from "../../components/Settings";
import { fetchEth } from "./utils/fetchPrice";
import { formatSeconds } from "../../utils/formatSeconds";

export default function Home() {
    const navigate = useNavigate();
    const params = useParams()
    const { open } = useWeb3Modal()
    const { walletProvider } = useWeb3ModalProvider()

    const { user, setUser, refCode, setRefCode } = useContext(UserContext);
    const { reset, roomNumber, setRoomNumber, group, setGroup, setYourColor, wallet, setWallet, wager, setWager, setOpponentWallet, lockingStatus, setLockingStatus, inQueue, setInQueue, gameOngoing, setGameOngoing, calledMatchFound, setCalledMatchFound} = useContext(RoomContext);
    const { contract, setContract } = useContext(ContractContext);
    const { createLoadingMessage } = useContext(LoadingContext);
    const { showSettings } = useContext(SettingsContext)
    const [wagers, setWagers] = useState<wagers | null>(null);
    const [showCustomGame, setShowCustomGame] = useState(false)
    const [codeInput, setCodeInput] = useState('')
    const [refAddress, setRefAddress] = useState('')

    const [seconds, setSeconds] = useState(0)



    const { isConnected } = useWeb3ModalAccount()
    useEffect(() => {


        const getContract = async () => {
            try {
                
                if(!walletProvider) return
                
                const provider = new ethers.BrowserProvider(walletProvider)
                const signer = await provider.getSigner()
                const contract_ = new ethers.Contract(
                    CONTRACT_ADDRESS,
                    ChessEth.abi,
                    signer
                );
                setContract(contract_);
            } catch (error) {
                console.error(error);
                
            }
        }
        getContract()
    }, [walletProvider]);
    
    useEffect(() => {
        
        if(!isConnected && wallet && user) {
            setWallet(null)
            signOutUser();
            setUser(null)
        }

    }, [isConnected]);
    
    useEffect(() => {
        try {
            const getWagersFromStore = async () => {
                const wagers = await getWagers();
                setWagers(wagers);
            };
            if(!wagers) {
                getWagersFromStore();
            }
        } catch (error) {}

        const refParam = params.ref
        
        if(refParam) {
            getAddress(refParam.toUpperCase())
        }
        if (!wallet) {
            signOutUser();
        }
        setInQueue(false)
    }, []);


    useEffect(() => {
        if (!user || !group || !roomNumber) return;
        const joinRoom = listenForValue(
            `${group}/${roomNumber}/status`,
            async (snapshot: DataSnapshot) => {
                try {
                    const status = snapshot.val()
                    if (status === 'locking') {
                        const data = await getRoom(group, roomNumber);
                        const _opponent =
                            data.players[0].wallet === wallet
                                ? data.players[1]
                                : data.players[0];
                        if (_opponent != null && !calledMatchFound) {
                            setOpponentWallet(_opponent.wallet);
                            setYourColor(data.players[0].id === user.uid? data.players[0].piece: data.players[1].piece);
                            matchFound();
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        );

        const startGame = listenForValue(
            `${group}/${roomNumber}`,
            (snapshot: DataSnapshot) => {
                const room = snapshot.val() as room;
                if(!room) return
                if (room.status === "locking") {
                    if ( room.players[0].locked && room.players[1].locked) {
                        setGameOngoing(true);
                        initGame(group, roomNumber, user);
                        setStatus(group, roomNumber, "onGoing");
                        navigate(`/${group}/${roomNumber}`);
                    }
                }
            }
        );
        return () => {
            if(joinRoom) joinRoom()
            if(startGame) startGame()
        }
    }, [roomNumber]);

    const findMatch = async (_wager: string, _group: string) => {
        try {
            if ( (!window.ethereum && !walletProvider) || ! user || !wallet || !contract || inQueue) return;
            setInQueue(true);
            const provider = new ethers.BrowserProvider(window.ethereum ?? walletProvider as any)
            const balance = await provider.getBalance(wallet);
            const lockedTokens = await contract.lockedTokensOfAddress(wallet);
        
            if (balance + lockedTokens < ethers.parseEther(_wager)) {
                reset()
                return createLoadingMessage("Not enough balance in wallet", "fail");
            }
            setWager(_wager);
            createLoadingMessage('Joining room ...','loading')
            const {data} = await joinRoom(_group, wallet) as any
            
            if(data && data.hasOwnProperty('group')){
                setRoomNumber(data.roomNumber.toString())
                setGroup(data.group)
                createLoadingMessage('Rejoined game','success')
                navigate(`/${data.group}/${data.roomNumber}`);
                return
            }
            const rNum = data.toString()
            if((parseFloat(rNum) >= 0)) {
                setRoomNumber(rNum)
                setGroup(_group);
                createLoadingMessage('Joined room','success')

            } else {
                throw new Error()
            }
        } catch (error) {
            console.error(error);
            reset()
            createLoadingMessage('Failed to join room', "fail");
        }
    };

    const matchFound = () => {
        if (!wager || lockingStatus != "not-locking" || calledMatchFound)
            return console.error("failed to call lock");
        lockTokens();
    };

    const lockTokens = async () => {
        if (
            !contract ||
            !user ||
            !roomNumber ||
            !group ||
            !wager ||
            !wallet ||
            gameOngoing ||
            calledMatchFound ||
            refAddress == wallet
        ) return;

        try {
            setCalledMatchFound(true);
            const lockedTokens = await contract.lockedTokensOfAddress(wallet);


            setLockingStatus("locking");
            createLoadingMessage('Locking tokens...', "loading");

            const room = await getRoom(group, roomNumber)

            const wagerInBig = ethers.parseEther(room.wager);

            const fillUpWager = wagerInBig - lockedTokens < 0
                ? BigInt(0)
                : wagerInBig - lockedTokens;

            const isValidAddress = ethers.isAddress(refAddress)

            const address0 = ethers.ZeroAddress

            console.log(lockedTokens, fillUpWager);
            

            const joinTxn = await contract.lockTokens(
                wagerInBig,
                isValidAddress? refAddress : address0,
                {
                    value: fillUpWager,
                }
            );
            const signTimeOut = setTimeout(() =>{throw new Error('Sign expired')}, 60000)
            await joinTxn.wait();
            clearTimeout(signTimeOut)
            validateLock(group, roomNumber, wallet, wager);
            setLockingStatus("locked");
            createLoadingMessage('Tokens locked', "success");
        } catch (e) {
            console.error(e);
            createLoadingMessage('Failed to lock tokens', "fail");
            setLockingStatus("not-locking");
            setCalledMatchFound(false);
            reset();
            if (!group || !roomNumber) return;
            deleteRoom(group, roomNumber, "failed-lock", wallet);
        }
    };

    const leaveQueue = () => {
        if (roomNumber && group && user && wallet) {
            deleteRoom(group, roomNumber, "leave-queue", wallet);
        };
        reset();
        setInQueue(false)
    };

    const formatCodeInput = (c: string) => {
        if(c.length < 10) {
            setCodeInput(c.toUpperCase())
        }
    }

    const getAddress = async (code: string) => {
        if(!code) return
        try {
            const {address} = await getAddressFromCode(code.toUpperCase()) as {address: string}
            if(!address) return createLoadingMessage("Invalid referral code", 'fail')
            if(address == wallet) return createLoadingMessage("Can't use own code", 'fail')
            setRefAddress(address)
            setRefCode(code)
        } catch (error) {
            
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if(inQueue) {
                setSeconds((prevSeconds) => prevSeconds + 1);

            } else {
                setSeconds(0)
            }
        }, 1000); 
        return () => clearInterval(interval);
      }, [inQueue]); 

    return (
        <div className="home-page">

            {wallet && user && inQueue && !showCustomGame ? 
            <div className="queue-page">
                <p className="fs-2">Waiting for opponent...</p>
                <p className="fs-3">{formatSeconds(seconds)}</p>
                <img className="chessboard-home" src={chessBoardHome} alt="" />
                    {roomNumber && <button
                    className="button button--leave-queue fs-7"
                    onClick={leaveQueue}>
                    Leave queue
                </button>}
            </div> : wallet && user && showSettings? <Settings /> :
            wallet && user && !showCustomGame? (
                <>
                    <div className="container flex">
                        <div className='distance-1'>
                            <div className="join-game-buttons">
                                {wagers !== null && (
                                    <>
                                        <JoinGameButton
                                            wager={wagers.low}
                                            group={"group_1"}
                                            findMatch={findMatch}
                                            piece={"p"}
                                            referred={refAddress.length == 42}
                                        />
                                        <JoinGameButton
                                            wager={wagers.medium}
                                            group={"group_2"}
                                            findMatch={findMatch}
                                            piece={"n"}
                                            referred={refAddress.length == 42}
                                        />
                                        <JoinGameButton
                                            wager={wagers.high}
                                            group={"group_3"}
                                            findMatch={findMatch}
                                            piece={"q"}
                                            referred={refAddress.length == 42}
                                        />
                                        <CustomGameButton showCustomGame={() =>{if(inQueue) return; setShowCustomGame(true)}}/>
                                    </>
                                )}
                            </div>
                        
                            <div className="text-center ref-field">
                                {refAddress.length != 42? <>
                                    <p className="fs-7">Referred by a friend? Use the refferal code to earn more on each game.</p>
                                    <div className="row center">
                                        <input value={codeInput} onChange={e => formatCodeInput(e.currentTarget.value)} className="input ref-input" type="text" />
                                        <button onClick={() => getAddress(codeInput)} className="button header-button">Register</button>
                                    </div> 
                                </> :
                                <h4>You are using code {refCode}</h4>}
                            </div>
                        </div>
                    </div>
                </>
            ) : user && wallet && showCustomGame? <CustomGame showCustomGame={() => setShowCustomGame(false)} />
             : (
                <div className="grid--split">
                    <div>
                        <h1 className="fs-1 lh1 title">ChessChain</h1>
                        <h2 className="text-center fs-4">Win Chess, Win Crypto</h2>
                        <ul className="distance-2 margin-top-1">
                            <li className="row"><img className="icon-small home-icon" src={pawn} alt="" /><span className="fs-6">Connect wallet: Connect to the Arbitrum One network</span></li>
                            <li className="row"><img className="icon-small home-icon" src={knight} alt="" /><span className="fs-6">Select wager bracket: Select the wager and you will be placed in a queue</span></li>
                            <li className="row"><img className="icon-small home-icon" src={rook} alt="" /><span className="fs-6">Lock tokens Sign the transaction to confirm and lock your tokens</span></li>
                            <li className="row"><img className="icon-small home-icon" src={queen} alt="" /><span className="fs-6">Play chess: Play, Strategize, Win - winner takes all</span></li>
                        </ul>

                    </div>
                    <div className="home-join flex-vert gap-1">
                        <img className="chessboard-home" src={chessBoardHome} alt="" />
                        <div onClick={() => open()} className='playnow-button pointer'></div>
                    </div>

                    <div className="distance-1 home-grid-3">
                    </div>

               </div>

            )}
        </div>
    );
}


