import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter,HashRouter} from 'react-router-dom'
import icon from "./assets/imgs/favicon.png";


import { UserContextProvider } from './context/UserContext';
import { RoomContextProvider } from './context/RoomContext';
import { ContractContextProvider } from './context/ContractContext';
import { LoadingContextProvider } from './context/LoadingContext';
import { SettingsContextProvider } from './context/SettingsContext';

import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import { PromotionContextProvider } from './context/PromotionContext';

// 1. Get projectId
const projectId = 'da3dfa3f34464d1155d50f14b79688e1'

// 2. Set chains
const arbitrum = {
  chainId: 42161,
  name: 'Arbitrum',
  currency: 'ETH',
  explorerUrl: 'https://arbiscan.io',
  rpcUrl: 'https://arb1.arbitrum.io/rpc'
}


// 3. Create a metadata object
const metadata = {
  name: 'ChessChain',
  description: 'ChessChain',
  url: 'https://chesschain.io/', // origin must match your domain & subdomain
  icons: [icon]
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,
})

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [arbitrum],
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter basename='/'>
      <UserContextProvider>
        <SettingsContextProvider>
            <ContractContextProvider>
                <RoomContextProvider>
                    <LoadingContextProvider>
                      <PromotionContextProvider>
                        <App />
                      </PromotionContextProvider>
                    </LoadingContextProvider>
                </RoomContextProvider>
            </ContractContextProvider>
        </SettingsContextProvider>
      </UserContextProvider>
    </BrowserRouter>
);

