import { Children, useContext, useState, useEffect, useMemo } from "react"
import { SettingsContext } from "../../../context/SettingsContext"
import { inCheck, tempChess } from "../utils/Game"
import { PromotionContext } from "../../../context/PromotionContext"

export default function Square({position, lastMove, children, black,highlightThisPosition, turn, highlightSelect, yourColor}){

    const {highlights} = useContext(SettingsContext)

    const [highlightLastMoveFrom, setHighlightLastMoveFrom] = useState('')
    const [highlightLastMoveTo, setHighlightLastMoveTo] = useState('')
    const {promotion} = useContext(PromotionContext)

    useEffect(() => {
        if(!lastMove || promotion) return
        const highlightLastMoveFrom = lastMove.from === position && yourColor === turn
        const highlightLastMoveTo = lastMove.to === position && yourColor === turn
        setHighlightLastMoveFrom(highlightLastMoveFrom)
        setHighlightLastMoveTo(highlightLastMoveTo)
        
    }, [lastMove]);

    const childrenLength = useMemo(() => {
        try {
            return Children.count([...children].filter(c => c))
        } catch (error) {
            return 0;
        }
    },[children])
    
    

    const bg = black? 'square-black' : 'square-white'
    const captureHighlight = childrenLength >= 1? highlightThisPosition && children[0].props.piece.color !== turn : ''
    const checkHighlight = inCheck() && childrenLength > 1 && children[0].props.piece?.color === turn && turn === tempChess.turn() && children.props.piece?.type === 'k'

    
    return(
        <div className={`board-square ${highlightLastMoveFrom? 'highlight-last-move-from' : highlightLastMoveTo? 'highlight-last-move-to' : highlightSelect ? 'highlight-select' : bg} ${highlights && (checkHighlight || captureHighlight) ? 'highlight' : ''}`}>
             {children}
             {childrenLength === 0 && highlightThisPosition && highlights && <div className="highlight-dot"></div>}
        </div>
    )
}