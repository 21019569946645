import { useContext, useState, useEffect } from "react";
import { ethers} from 'ethers'

import { Link, useLocation} from "react-router-dom"
import { signInGuest } from "../utils/firebase-utils/firebase.utils";
import infoIcon from "../svgs/infoIcon.svg";
import { onAuthStateChangedListener } from "../utils/firebase-utils/firebase.utils";
import { useWeb3Modal } from '@web3modal/ethers/react'
import { useWeb3ModalAccount, useWeb3ModalProvider  } from '@web3modal/ethers/react'

import { UserContext } from "../context/UserContext";
import { RoomContext } from "../context/RoomContext";
import { LoadingContext } from "../context/LoadingContext";
import { SettingsContext } from "../context/SettingsContext";
import kingB from "../features/chess/svgs/k-b.svg";
import queenW from "../features/chess/svgs/q-w.svg";

import ethIcon from '../assets/imgs/Ethereum_small.png'
import settingsIcon from '../assets/imgs/gear_icon.png'
import headerIcon from '../svgs/header_icon.png'
import { fetchEth } from "../features/home/utils/fetchPrice";



function Header() {

    const [balance, setBalance] = useState('')
    const { user, setUser, refCode } = useContext(UserContext);
    const ref = refCode.toLowerCase()
    const { wallet, setWallet, gameOngoing } = useContext(RoomContext);
    const { createLoadingMessage} = useContext(LoadingContext)
    const {setShowSettings, buttonRef, wagerIsInEth} = useContext(SettingsContext)

    const { address, chainId, isConnected } = useWeb3ModalAccount()
    const { walletProvider } = useWeb3ModalProvider()

    const { open } = useWeb3Modal()

    const location = useLocation();

    const [ethPrice, setEthPrice] = useState(0)

    useEffect(() => {

        const fetchEthPrice = async () => {
            const data = await fetchEth()
            setEthPrice(data)
        }
        if(wagerIsInEth ) {
            fetchEthPrice()

        }

        const unsubscribe = onAuthStateChangedListener((user) => {
            
            if (user) {
                setUser(user);
            }
        });
        return unsubscribe
    }, []);

    useEffect(() => {
        if(!address || !isConnected) return
        if( chainId !== 42161) return createLoadingMessage('Please connect to Arbitrum One (chain ID 42161)', 'fail')
        setWallet(address)
        signInGuest(address)
        const getBalance = async() => {
            const provider = new ethers.BrowserProvider(walletProvider as any)
            const balance = await provider.getBalance(address);
            setBalance(parseFloat(ethers.formatEther(balance)).toFixed(3))
        }

        getBalance()
        
    }, [address,chainId, walletProvider, isConnected]);

    useEffect(() => {
        const getBalance = async() => {
            if(!walletProvider|| !wallet) return
            const provider = new ethers.BrowserProvider(walletProvider)
            const balance = await provider.getBalance(wallet);
            setBalance(parseFloat(ethers.formatEther(balance)).toFixed(3))
        }

        getBalance()
        
    }, [wallet, gameOngoing, walletProvider]);


    const walletStr = `${wallet?.slice(0, 6)}..`;

    return (
        <header className="header">
            <div>
                <img className="header-icon" src={headerIcon} alt="" />
            </div>
            {user && wallet && (
                <nav className="header-nav">

                    {(location.pathname == '/' || location.pathname == `/${ref}` || location.pathname == '/referral-program') && 
                        <Link className={`wide-only fs-7 header-link ${location.pathname == '/referral-program' ? 'home-link' : 'ref-link'}`} to={location.pathname == '/referral-program' ? `/${ref ??  ''}` : '/referral-program' }>{location.pathname == '/referral-program'? 'Home' : 'Referral program'}</Link>  
                    }
                    <p className="header-button">{wagerIsInEth ? balance : Math.round(parseFloat(balance) * ethPrice)} { wagerIsInEth? <img className="eth-icon-small" src={ethIcon} /> : "$"} </p>
                    <p className="pointer header-button" onClick={() => open()}>{walletStr}</p>
                    <img ref={buttonRef} className="pointer icon" src={settingsIcon} onClick={() => setShowSettings(prev => !prev)} alt="" />
                </nav>
            )}

        </header>
    );
}

export default Header;




