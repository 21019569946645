import "./styles/App.css";
import "./styles/mobile.css"
import "./styles/wide.css"
import { Routes, Route } from "react-router-dom";

import Chess from "./features/chess/Chess";
import Home from "./features/home/Home";
import Loading from "./components/Loading";
import Referrals from "./components/Referrals";
import Header from "./components/Header";

function App() {
    return (
        <>
            <div className="App">
                <Header />
                <Loading />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/:ref" element={<Home />} />
                    <Route path="/:group/:room" element={<Chess />} />
                    <Route path="/referral-program" element={<Referrals />} />
                </Routes>
            </div>
        </>
    );
}

export default App;
