import { useState, useContext, useEffect } from 'react'
import {ethers} from 'ethers'
import { LoadingContext } from '../../../context/LoadingContext'
import { RoomContext } from '../../../context/RoomContext';
import { ContractContext } from '../../../context/ContractContext';
import { cancelCustomGame, createCustomGame, joinCustomGame, getRoom, getRoomsInGroup, listenForValue, setStatus, leaveCustomGame } from '../../../utils/firebase-utils/firebase.utils';
import { DataSnapshot } from 'firebase/database';
import { room } from '../../../types/types';
import copy from '../../../assets/imgs/copy_icon.png'
import close from '../../../assets/imgs/x_icon.png'
import { useWeb3ModalProvider } from '@web3modal/ethers/react';
import { UserContext } from '../../../context/UserContext';
import { SettingsContext } from '../../../context/SettingsContext';
import { fetchEth } from '../utils/fetchPrice';


export function CustomGame(props: any) {
    const [create, setCreate] = useState(false)
    const [join, setJoin] = useState(false)

    const back = () => {
        setJoin(false)
        setCreate(false)
    }

    return ( 
        <div className='container flex custom-game'>
           {!create && !join &&
            <>
                <button className='button back' onClick={props.showCustomGame}>Back</button>
                <div className='flex gap-1'>
                    <button className='button create-custom-game custom-game-button' onClick={() => setCreate(true)}>
                    </button>
                    <button className='button join-custom-game custom-game-button' onClick={() => setJoin(true)}>
                    </button>
                </div> 
            </>}
            {create && !join && <CreateGame back={back} />}
            {join && !create && <JoinGame back={back} />}
        </div>
    );
}

function JoinGame ({back}: {back: () => void}) {

    const {wallet, wager, setWager, setGroup, setRoomNumber, setInQueue} = useContext(RoomContext);
    const {createLoadingMessage} = useContext(LoadingContext)
    const {contract} = useContext(ContractContext)
    const {user} = useContext(UserContext)
    const { wagerIsInEth} = useContext(SettingsContext)

    const { walletProvider } = useWeb3ModalProvider()
        
    const [joinCodeInput, setJoinCodeInput] = useState('')
    const [joinCode, setJoinCode] = useState('')
    const [gameWager, setGameWager] = useState('')
    const [gameTotalGameTime, setGameTotalGameTime] = useState(0)
    const [gameCreator, setGameCreator] = useState('')
    const [rotate, setRotate] = useState<'rotate' | 'paused'>('paused')
    const [roomStatus, setRoomStatus] = useState('')
    const [ethPrice, setEthPrice] = useState(0)

    const [allPublicRooms, setAllPublicRooms] = useState<room[]>()

    useEffect(() => {
        const fetchEthPrice = async () => {
            const data = await fetchEth()
            setEthPrice(data)
        }
        if(wagerIsInEth ) {
            fetchEthPrice()

        }
        fetchRooms()
    }, []);

    const fetchRooms = () => {
        try {
            setRotate('rotate')
            setTimeout(() => {
                setRotate('paused')
            },500)
            getRoomsInGroup('custom').then( data => {
                if(!data) return
                const publicRooms = Object.values(data).filter((r: any) => r.visibility === 'public' && r.players.length === 1 && r.players[0].joinedAt + 1800000 > Date.now() ) as room[]
                if(!publicRooms) return;
                setAllPublicRooms(publicRooms)
            })
        } catch (error) {
            console.log(error);
        }
        
    }

    const joinRoom = async (_joinCode: string) => {
        createLoadingMessage('Joining room...','loading')
        
        if(!wallet || !walletProvider || !contract || !user) return createLoadingMessage('Failed to join room','fail')
        const room = await getRoom('custom', _joinCode)
        if(!room) return createLoadingMessage('Failed to join room', 'fail')
        const provider = new ethers.BrowserProvider(walletProvider)
        const balance = await provider.getBalance(wallet);
        const lockedTokens = await contract.lockedTokensOfAddress(wallet);
        if (balance + lockedTokens < ethers.parseEther(room.wager)) {
            return createLoadingMessage('Not enough balanace in wallet','fail')
        }

        createLoadingMessage('Joining game...','loading')
        if(!_joinCode || !wallet) return;
        setJoinCode(_joinCode)
        try {
            await joinCustomGame(wallet, _joinCode)
            createLoadingMessage('Joined game','success')
        } catch (error) {
            if(error instanceof Error) {
                createLoadingMessage('Failed to join room','fail')
            }
        }
        setGameWager(room.wager)
        setGameTotalGameTime(room.players[0].gameTimeLeft)
        setGameCreator(room.creator)
        setWager(room.wager)
        setGroup(room.group)
        setRoomNumber(room.roomNumber)
        setRoomStatus(room.status)
        setInQueue(true)
        listenForValue(`custom/${_joinCode}`,  async (snapshot: DataSnapshot) => {
            if(!snapshot.exists()) {
                setGameWager('')
                setGameTotalGameTime(0)
                setGameCreator('')
                setWager('')
                setGroup(null)
                setRoomNumber(null)
                setInQueue(false)
            }else {
                const room = snapshot.val()
                if(!room.players[1] || room.players[1].wallet !== wallet){
                    setGameWager('')
                    setGameTotalGameTime(0)
                    setGameCreator('')
                    setWager('')
                    setGroup(null)
                    setRoomNumber(null)
                    setInQueue(false)
                }
            }
        })
    }


    const publicRooms = allPublicRooms?.map((room, i) => (
        <tr key={i} className='table-row'>
            <td>{`${room.creator?.slice(0, 6)}..`}</td>
            <td>{wagerIsInEth ? room.wager : Math.round(parseFloat(room.wager) * ethPrice)} {wagerIsInEth ? "ETH" : "$"}</td>
            <td>{(room.players[0].gameTimeLeft / 60000).toFixed()} min</td>
            <td><button className='button button-light-thin' onClick={() => joinRoom(room.roomNumber)}>Join Game</button></td>
        </tr>
    ))
        
    const table = (
        <div className="table">
            <div className='flex center gap-1'>
                <p className="fs-3">Public games</p>
                <button onClick={fetchRooms} className={`button pointer refresh ${rotate}`}>
                </button>
            </div>
            <div className='table-container'>
                <table className='public-games-table'>
                    <thead>
                        <tr>
                            <th>Creator</th>
                            <th>Wager</th>
                            <th>Game time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {publicRooms}
                    </tbody>
                </table>

            </div>
        </div>
    )

    const leaveGame = async () => {
        if(!wallet || !joinCode) return;
        if(roomStatus != 'waiting') return createLoadingMessage('Cannot leave game that has started', 'fail')
        createLoadingMessage('Leaving room...','loading')
        await leaveCustomGame(wallet, joinCode)
        setGameWager('')
        setGameTotalGameTime(0)
        setGameCreator('')
        setWager('')
        setGroup(null)
        setRoomNumber(null)
        setInQueue(false)
    }

    return ( 
        <div className='join-custom-page'>
            {!gameCreator?
                <>
                    <button className='button back' onClick={back}>Back</button>
                    <div className='join-custom-header row'>
                        <div className="row">
                            <p className='fs-5'>Join code:</p>
                            <input className='custom-game-code-input input fs-5' type="text" value={joinCodeInput} onChange={e => setJoinCodeInput(e.target.value)} />
                        </div>
                        <button className='button button-light-thin' onClick={() => joinRoom(joinCodeInput)}>Join Game</button>
                    </div>
                    <br />
                    {table}
                </> :
                <div className='start-cc-game gap-1 flex-vert justify-between'>
                    <p className="fs-6">Waiting for creator to start...</p>
                    <table className='game-settings'>
                        <tbody>
                            <tr><td>Creator: </td><td className=''>{`${gameCreator.slice(0, 6)}..`}</td></tr>
                            <tr><td>Wager: </td><td className=''>{gameWager}</td></tr>
                            <tr><td>Game time: </td><td className=''>{gameTotalGameTime/60000}m</td></tr>
                        </tbody>
                    </table>
                    <button className='button self-end unselected' onClick={leaveGame}>Leave Game</button>
                </div>
            }
        </div>
    )
}

function CreateGame({back}: {back: () => void}) {
    const { contract } = useContext(ContractContext);
    const {wagerIsInEth} = useContext(SettingsContext)
    const { wallet, wager, setWager, setGroup, setRoomNumber, setInQueue, reset } = useContext(RoomContext);
    const { createLoadingMessage } = useContext(LoadingContext);
    const { walletProvider } = useWeb3ModalProvider()

    const [ethPrice, setEthPrice] = useState(0)

    
    const [customWager, setCustomWager] = useState(wagerIsInEth? 0.02 : parseFloat((0.02 * ethPrice).toFixed()))
    const [customTotalGameTime, setCustomTotalGameTime] = useState(600000)
    const [visibility, setVisibility] = useState<'private' | 'public'>('public')
    const [joinCode, setJoinCode] = useState('')
    
    const [gameWager, setGameWager] = useState('')
    const [gameTotalGameTime, setGameTotalGameTime] = useState(0)
    const [second, setSecond] = useState<string | null>(null)
    const [gameCreated, setGameCreated] = useState(false)
    const [ready, setReady] = useState(false)

    useEffect(() => {
        const fetchEthPrice = async () => {
            const data = await fetchEth()
            setEthPrice(data)
            setCustomWager(Math.round(data * 0.02))
        }
        if( !wagerIsInEth) {
            fetchEthPrice()
        }
        return () =>  cancel(wallet!, joinCode)
    },[])

    const createGame = async () => {
        try {
            if(!walletProvider || !wallet || !contract || !customTotalGameTime || !customWager) throw new Error();
            if(wagerIsInEth? customWager < 0.001 : customWager < 0.001 * ethPrice) throw new Error(`Minimum wager ${wagerIsInEth && ethPrice != 0 ? "0.001 ETH" : 0.001 * ethPrice + "$"}`) 
                if(wagerIsInEth ? customWager > 1 : customWager > ethPrice) throw new Error(`Minimum wager ${wagerIsInEth && ethPrice != 0 ? "1 ETH" : ethPrice + "$"}`)
            const wager = wagerIsInEth? customWager : parseFloat((customWager / ethPrice).toFixed(3))
            const provider = new ethers.BrowserProvider(walletProvider)
            const balance = await provider.getBalance(wallet)
            const lockedTokens = await contract.lockedTokensOfAddress(wallet)
            if (balance + lockedTokens < ethers.parseEther(wager.toString())) {
                reset()
                throw new Error('Not enough balance in wallet');
            }
            createLoadingMessage("Creating room...", "loading")
            const i = Math.floor(Math.random() * 20)
            const roomCode = wallet.substring(i, i + 6).toUpperCase()
            setJoinCode(roomCode)
            const {data} = await createCustomGame(visibility, roomCode ,wager.toString(), wallet, customTotalGameTime) as any
            
            if(data !== roomCode) throw new Error('Failed to create room')
            createLoadingMessage("Room created", "success")
            setGameCreated(true)
            setGameWager(wager.toString())
            setGameTotalGameTime(customTotalGameTime)
            
            listenForValue(`custom/${roomCode}`, async (snapshot: DataSnapshot) => {

                try {
                    
                    const room = snapshot.val()
                    if(room && room.players.length === 2 && room.status === 'waiting'){
                        setSecond(room.players[1].wallet)
                        setReady(true)
                        createLoadingMessage("Ready to start game", "success")
                    }
                } catch (error) {
                    throw new Error();
                    
                }
            })
    } catch (error) {
        if (error instanceof Error) {
            createLoadingMessage(error.message, 'fail')
          } else {
              createLoadingMessage("Failed to create game", 'fail')
          }
    }
}

    const startGame = () => {
        if(!wallet || !visibility) return
        setStatus('custom', joinCode, 'locking')
        setWager(gameWager)
        setGroup('custom')
        setRoomNumber(joinCode)
        createLoadingMessage('Starting game...','loading')
        setInQueue(true)
    }

    const cancel = (wallet: string, joinCode: string) => {
        if(!wallet || !joinCode) return
        cancelCustomGame(joinCode, wallet)
        createLoadingMessage("Canceled game", "success")
        setGameCreated(false)
    }

    const removePlayer = async() => {
        if(!wallet || !joinCode) return
        createLoadingMessage('Removing player', 'loading')
        await leaveCustomGame(wallet, joinCode)
        createLoadingMessage('Removed player', 'success')
        setSecond(null)
        setReady(false)
    }

    const formatWager = (input: string) => {
        let value = input.replace(/[^0-9.]/g, '');

       setCustomWager(Number(value))
    };

    return (
        <div className='create-custom-page'>
           {!gameCreated && <button className='button back' onClick={back}>Back</button>}
            {!gameCreated ? <div className='create-form'>
            <p>Select bet amount</p>
            <div className="input-row">
                <input className='wager-input' type='number' value={customWager} onChange={e => formatWager(e.target.value)} />
                <p>{wagerIsInEth? "ETH" : "$"}</p>
            </div>
               <p>Select game time</p>
           <div className='row'>
               <button className={`button button-select ${customTotalGameTime === 600000 ? 'selected' : 'unselected'}`} onClick={() => setCustomTotalGameTime(600000)}>10 min</button>
               <button className={`button button-select ${customTotalGameTime === 900000 ? 'selected' : 'unselected'}`} onClick={() => setCustomTotalGameTime(900000)}>15 min</button>
               <button className={`button button-select ${customTotalGameTime === 1800000 ? 'selected' : 'unselected'}`} onClick={() => setCustomTotalGameTime(1800000)}>30 min</button>
           </div>
           <div className='row justify-between'>
            <div className='flex gap-2'>
               <button className={`button button-select ${visibility === 'public' ? 'selected' : 'unselected'}`} onClick={() => setVisibility('public')}>Public</button>
               <button className={`button button-select ${visibility === 'private' ? 'selected' : 'unselected'}`} onClick={() => setVisibility('private')}>Private</button>
            </div>
            <button className='button light-button wide-only' onClick={createGame}>Create game</button>
           </div>
            <button className='button light-button mobile-only self-end' onClick={createGame}>Create game</button>
       </div>
        :
       <div className='start-cc-game'>
        <div className='flex-vert gap-1 align-start'>
            <div className='row'>
               <p>Invite code:</p>
               <div onClick={() => navigator.clipboard.writeText(joinCode)} className="row pointer">
                <p>{joinCode}</p>
                <img src={copy} alt="" className='icon'/>
               </div>
            </div>
            {second?
                <div className='row'>
                    <p>Opponent:</p>
                    <div className='row'>
                        <p>{second?.slice(0, 6)}..</p>
                        <img src={close} alt="" className='icon pointer' onClick={removePlayer} />
                    </div>
                </div> : <p>Waiting for opponent to join</p>}
            <table className="game-settings">
                <tbody>
                    <tr><td>Wager:</td><td>{gameWager}</td></tr>
                    <tr><td>Game time:</td><td>{gameTotalGameTime/60000}m</td></tr>
                </tbody>
            </table>

        </div>
           <div className='row justify-between'>
                <button className='button button-img unselected fs-7' onClick={() => cancel(wallet!, joinCode)}>Cancel Game</button>
                {ready && <button className='button button-img selected fs-7' onClick={startGame}>Start Game</button>}
           </div>
       </div>}
        </div>
    );
}


