import { useContext, useState, useEffect } from "react";
import { RoomContext } from "../context/RoomContext";
import { LoadingContext } from "../context/LoadingContext";
import Header from "./Header";
import { getCodeFromAddress } from "../utils/firebase-utils/firebase.utils";

import {postRefCode} from "../utils/firebase-utils/firebase.utils"

import pawn from '../features/chess/imgs/p-b.png'
import knight from '../features/chess/imgs/n-w.png'
import rook from '../features/chess/imgs/r-b.png'
import Settings from "./Settings";
import { SettingsContext } from "../context/SettingsContext";



function Referrals() {

    const { wallet } = useContext(RoomContext);
    const { showSettings } = useContext(SettingsContext)

    const { createLoadingMessage } = useContext(LoadingContext);

    const [code, setCode] = useState('')
    const [refCode, setRefCode] = useState('')

    const codeInput = (c: string) => {
        if(c.length < 10) {
            setCode(c)
        }
    }

    useEffect(() => {
        if (!wallet) return
        const checkIfHasCode = async () => {
            try {
                const {code} = await getCodeFromAddress(wallet) as {code: string}
                if(code) {
                    setRefCode(code)
                }
            } catch (error) {
                
            }
        }
        checkIfHasCode()
        
    }, [wallet]);

    const generateCode = async () => {
        createLoadingMessage('Generating code...', 'loading')
        if(!code || !wallet) return;
        const {data} = await postRefCode(code, wallet) as any
        if(data == 'ok') {
            setRefCode(code)
            createLoadingMessage('Code generated','success')
        } else {
            if(data == 'invalid-address') createLoadingMessage('Address already has a code', 'fail')
            if(data == 'invalid-code') createLoadingMessage('Code is used. Try another one', 'fail')
            createLoadingMessage('Failed to generate code', 'fail')
        }
    }


    return showSettings ? <Settings /> : (

        <div className="grid grid--split lh-1 ref-grid">
            <div className="distance-1 fs-2">Join Our Referral Program <br/> and Earn Together!</div>
            <div className="wide-only"></div>
            <div>
                <h3>How it works</h3>
                <ul className="lh-2 distance-2 fs-6">
                    <li className="flex"><img className="icon-small" src={pawn} alt="" /><span>Get a refferal code: <span className="text-accent">Chose what your referral code will be. The code will be associated with your connected address</span></span></li>
                    <li className="flex"><img className="icon-small" src={knight} alt="" /><span>Refer a Friend: <span className="text-accent">Invite your friends or fellow chess enthusiasts to join Chess Eth with your refferal code</span></span></li>
                    <li className="flex"><img className="icon-small" src={rook} alt="" /><span>Earn 25% on fees: <span className="text-accent">When your referred friend signs up and starts playing games, both you and your friend will earn 25% of the fees from every they game play</span></span></li>
                </ul>
            </div>
            {wallet? !refCode? <div className="flex gap-1">
                <input className="input custom-game-code-input fs-4" value={code} onChange={e => codeInput(e.currentTarget.value.toUpperCase())} placeholder="Your code" type="text" />
                <button className="button header-button" onClick={generateCode}>Generate</button>
            </div> 
            : 
            <h2 className="lh-2 fs-4">Your referral code is {refCode} <br></br> Thanks for spreading ChessChain</h2>
            :
            <p className="fs-5">Connect wallet to generate and link code to your wallet</p>
            }

        </div>
     );
}

export default Referrals;