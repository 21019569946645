import axios from 'axios'

export async function fetchEth() {
    try {
        const res = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd', { headers: {accept: 'application/json', 'x-cg-demo-api-key': process.env.REACT_APP_CG_API}})
        const price = res.data.ethereum.usd
        return price
    } catch (error) {
        return null
        
    }

      
} 