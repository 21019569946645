import { useEffect, RefObject } from "react";

export default function useOutsideClick<T extends HTMLElement>(
  ref: RefObject<T>,
  callback: () => void,
  ignoreRef?: RefObject<T>,
): void {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      
      if (ref.current && !ref.current.contains(event.target as Node) && (ignoreRef?.current && !ignoreRef.current.contains(event.target as Node)))  {
        callback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}
