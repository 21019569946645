import React, {useState, createContext, SetStateAction, Dispatch} from 'react'
import {ethers} from 'ethers'
import { chessMove } from '../types/types'
type context = {
    promotion: chessMove | null,
    setPromotion: Dispatch<SetStateAction<chessMove | null>>
}

type props = {
    children: React.ReactNode
}

export const PromotionContext = createContext<context>({} as context) 

export const PromotionContextProvider = ({children}: props) => {
    const [promotion, setPromotion] = useState<chessMove | null>(null)

    return (
        <PromotionContext.Provider value={{promotion,setPromotion}}>
            {children}
        </PromotionContext.Provider>
    )
}
